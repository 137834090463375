
import { Component, Vue, Inject } from 'vue-property-decorator';
import { IAnswer } from '@/shared/model/answer.model';
import { ICustomer } from '@/shared/model/customer.model';
import AlertService from '@/shared/alert/alert.service';
import TextFieldSettingService from '@/entities/text-field-setting/text-field-settings.service';
import ReportService from '@/entities/report/report.service';
import { ITextFieldSetting } from '@/shared/model/text-field-setting.model';
import ReportDetailPageV2 from '@/pages/report/components/report-detail-page-v2.vue';
import ReportOverviewPageV2 from '@/pages/report/components/report-overview-page-v2.vue';
import ReportCover from '@/pages/report/components/report-cover.vue';
import { createSummaryReport, transformToCustomTextFields } from '@/pages/report/lib/reportService';


@Component({
  components: {
    ReportCover,
    ReportOverviewPageV2,
    ReportDetailPageV2,
  },
})
export default class ReportPreview extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('textFieldSettingService')
  private textFieldSettingService: () => TextFieldSettingService;
  @Inject('reportService') private reportService: () => ReportService;

  get maxPages(): number {
    return this.customers.length + 2;
  }

  get customers(): ICustomer[] {
    return this.$store.getters.customer;
  }

  get answers(): IAnswer[] {
    return this.$store.getters.answers;
  }

  async mounted(): Promise<void> {
    this.initTextFields().then();
    this.loadFooter().then();
  }

  async loadFooter(): Promise<void> {
    try {
      //get main customer id
      const customerId = this.customers.find((c: ICustomer) => c.isMainCustomer)?.id;
      if (!customerId) {
        throw new Error('Main customer not found');
      }
      const footer = await this.textFieldSettingService().findFooter(customerId);
      this.$store.commit('setFooter', footer.data);
    } catch (e) {
      console.error(e);
      this.alertService().showError(this, 'Fehler beim Laden des Fußbereichs');
    }
  }

  async saveReport(): Promise<void> {
    try {
      const textFields = this.$store.getters.textFields;
      const summaryReport = createSummaryReport(this.answers, this.customers, textFields);
      const report = await this.reportService().createReport(summaryReport);
      await this.$router.push({ name: 'ReportDetails', params: { reportId: report.id + '' } });
    } catch (e) {
      this.alertService().showError(this, 'Beim Speichern ist ein Fehler aufgetreten');
    }
  }

  goBack() {
    this.$router.go(-1);
  }

  findAnswersForCustomer(customer: ICustomer): IAnswer[] {
    return this.answers.filter(item => item.customer.id === customer.id);
  }

  private async initTextFields(): Promise<void> {
    try {
      //load default settings
      const res: ITextFieldSetting[] = (await this.textFieldSettingService().retrieve()).data;
      const customers = this.$store.getters.customer;
      const allCustomTextFields = transformToCustomTextFields(res, customers);
      //update store
      this.$store.commit('setTextField', allCustomTextFields);
    } catch (e) {
      console.error(e);
      this.alertService().showError(this, 'Fehler beim Laden der Textfelder');
    }
  }

}
