import { IAnswer } from '@/shared/model/answer.model';
import { ISummaryReport } from '@/shared/model/summary-report.model';

export interface IReport {
  id?: number;
  note?: string | null;
  answers?: IAnswer[] | null;
  efmCustomerId: number | null;
  efmCustomerFremdId: number | null;
  mandantId: number | null;
  customerDob: Date | string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerStreet: string | null;
  customerZip: string | null;
  customerCity: string | null;
  customerJob: string | null;
  summaryReport: ISummaryReport | null;
}

export class Report implements IReport {
  constructor(
    public id?: number,
    public note?: string | null,
    public answers?: IAnswer[] | null,
    public efmCustomerId: number | null = null,
    public efmCustomerFremdId: number | null = null,
    public mandantId: number | null = null,
    public customerDob: Date | string | null = null,
    public customerFirstName: string | null = null,
    public customerLastName: string | null = null,
    public customerStreet: string | null = null,
    public customerZip: string | null = null,
    public customerCity: string | null = null,
    public customerJob: string | null = null,
    public summaryReport: ISummaryReport | null = null
  ) {}
}
