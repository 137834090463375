import { IAnswer } from '@/shared/model/answer.model';
import { CustomTextField, ICustomTextField } from '@/shared/model/custom-text-field.model';
import { ISummaryReport, SummaryReport } from '@/shared/model/summary-report.model';
import { IReport, Report } from '@/shared/model/report.model';
import { ITextFieldSetting, TextFieldType } from '@/shared/model/text-field-setting.model';
import { ICustomer } from '@/shared/model/customer.model';

/**
 * transform given answers and customers to a summary report
 * @param {IAnswer[]} answers list of answers
 * @param {ICustomer[]} customers list of customers
 * @param {ICustomTextField[]} customTextFields list of custom text fields
 * @return {ISummaryReport} summary report
 * @private
 */
export const createSummaryReport = (
  answers: IAnswer[],
  customers: ICustomer[],
  customTextFields: ICustomTextField[]
): ISummaryReport => {
  const reports: IReport[] = [];
  for (const customer of customers) {
    const report = new Report();
    //add further information here
    report.efmCustomerId = customer.id;
    report.efmCustomerFremdId = customer.fremdId;
    report.mandantId = customer.mandantId;
    report.customerDob = customer.dob;
    report.customerFirstName = customer.firstName;
    report.customerLastName = customer.lastName;
    report.customerStreet = customer.street;
    report.customerZip = customer.zip;
    report.customerCity = customer.city;
    //    report.customerJob = customer.job; //not implemented yet
    report.answers = answers.filter(item => item.customer.id === customer.id);
    reports.push(report);
  }
  const summaryReport = new SummaryReport();
  summaryReport.reports = reports;
  summaryReport.customTextFields = customTextFields;

  //set main customer
  const mainCustomer: ICustomer = customers.find(customer => customer.isMainCustomer);
  if (mainCustomer) {
    summaryReport.efmCustomerId = mainCustomer.id;
    summaryReport.efmCustomerFremdId = mainCustomer.fremdId;
    summaryReport.mandantId = mainCustomer.mandantId;
    summaryReport.betreuerFremdId = mainCustomer.betreuerFremdId;
  }
  return summaryReport;
};

/**
 * transform given (default) text field settings and customers to custom text fields
 * @param {ITextFieldSetting[]} textFieldSettings
 * @param {ICustomer[]} customers
 * @return {ICustomTextField[]}
 */
export const transformToCustomTextFields = (
  textFieldSettings: ITextFieldSetting[],
  customers: ICustomer[]
): ICustomTextField[] => {
  const customTextFields: CustomTextField[] = [];
  //loop all saved text field settings
  for (const setting of textFieldSettings) {
    if (setting.type === TextFieldType.INTRODUCTION_DETAIL_PAGE) {
      //create detail page introduction for each customer of current report
      for (const customer of customers) {
        const customTextField = new CustomTextField();
        customTextField.type = setting.type;
        customTextField.value = setting.value;
        customTextField.efmCustomerId = customer.id;
        customTextFields.push(customTextField);
      }
    } else {
      //create custom text field without customer for every other text field
      const customTextField = new CustomTextField();
      customTextField.type = setting.type;
      customTextField.value = setting.value;
      customTextFields.push(customTextField);
    }
  }
  return customTextFields;
};
