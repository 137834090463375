import { ICustomTextField } from "./custom-text-field.model";
import { IReport } from "@/shared/model/report.model";

export interface ISummaryReport {
  createdTimestamp?: Date | null;
  reports?: IReport[] | null;
  customTextFields?: ICustomTextField[] | null;
  id?: number;
  note?: string | null;
  efmCustomerId?: number | null;
  efmCustomerFremdId?: number | null;
  mandantId?: number | null;
  betreuerFremdId?: number | null;
  ccaDocumentId: number | null;
  ccaUpdatedAt: Date | null;
  updatedAt: Date | null;
}

export class SummaryReport implements ISummaryReport {
  constructor(
    public createdTimestamp?: Date | null,
    public reports?: IReport[] | null,
    public customTextFields?: ICustomTextField[],
    public id?: number,
    public note?: string | null,
    public efmCustomerId?: number | null,
    public efmCustomerFremdId?: number | null,
    public mandantId?: number | null,
    public betreuerFremdId?: number | null,
    public ccaDocumentId: number | null = null,
    public ccaUpdatedAt: Date | null = null,
    public updatedAt: Date | null = null
  ) {
  }
}
