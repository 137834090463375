var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { type: "button" },
          on: { click: _vm.goBack },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "arrow-left" } }),
          _vm._v("\n      Zurück zur Eingabe\n    "),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      [
        _vm._m(0),
        _vm._v(" "),
        _c("ReportCover", {
          attrs: { maxpages: _vm.maxPages, pagenum: 1, "show-edit": true },
        }),
        _vm._v(" "),
        _c("ReportOverviewPageV2", {
          attrs: { maxpages: _vm.maxPages, pagenum: 2, "show-edit": true },
        }),
        _vm._v(" "),
        _vm._l(_vm.customers, function (c, index) {
          return _c("ReportDetailPageV2", {
            key: c.id,
            attrs: {
              answers: _vm.findAnswersForCustomer(c),
              customer: c,
              maxpages: _vm.maxPages,
              pagenum: index + 3,
              "show-edit": true,
            },
          })
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.saveReport()
                },
              },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "save" } }),
              _vm._v("   Report Speichern\n      "),
            ],
            1
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("div", [
        _c("h2", [_vm._v("Report Vorschau & Kontrolle")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Bitte fügen Sie Anmerkungen hinzu und speichern Sie dann diesen Bericht."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }